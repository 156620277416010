import React, { Component } from 'react';
import csaImage from './assets/csa.svg';
import emulate3dImage from './assets/emulate3d.png';
import glueImage from './assets/glue.png';
import golfPlusImage from './assets/golfplus.png';
import lockheedImage from './assets/lockheed.png';
import nasaImage from './assets/nasa.svg';
import oxfordImage from './assets/oxford.png';
import saxoImage from './assets/saxo.svg';
import synergyImage from './assets/synergy.png';
import ugaImage from './assets/uga.png';
import viveportImage from './assets/viveport.png';
import vmwareImage from './assets/vmware.png';
import Carousel from '../Carousel';
import './styles.scss';

interface Customer {
  imageUrl: string;
  name: string;
  linkUrl: string;
  imageStyle?: React.CSSProperties;
}

const customers: Customer[] = [
  {
    name: 'VMWare',
    imageUrl: vmwareImage,
    linkUrl: 'https://www.vmware.com'
  },
  {
    name: 'National Aeronautics and Space Administration (NASA)',
    imageUrl: nasaImage,
    linkUrl: 'https://www.nasa.gov',
    // Tweak styles to position the logo correctly.
    imageStyle: {
      width: '135%',
      position: 'relative',
      left: '-10%',
    }
  },
  {
    name: 'Lockheed Martin',
    imageUrl: lockheedImage,
    linkUrl: 'https://www.lockheedmartin.com/'
  },
  {
    name: 'HTC Viveport',
    imageUrl: viveportImage,
    linkUrl: 'https://www.viveport.com'
  },
  {
    name: 'University of Oxford',
    imageUrl: oxfordImage,
    linkUrl: 'https://www.medsci.ox.ac.uk/study/graduateschool/supervisors/stephen-taylor',
    imageStyle: {
      borderRadius: '10px'
    }
  },
  {
    name: 'Canadian Space Agency (CSA)',
    imageUrl: csaImage,
    linkUrl: 'https://www.asc-csa.gc.ca/eng/Default.asp'
  },
  {
    name: 'Golf+ VR',
    imageUrl: golfPlusImage,
    linkUrl: 'https://www.golfplusvr.com'
  },
  {
    name: 'University of Georgia',
    imageUrl: ugaImage,
    linkUrl: 'https://www.uga.edu'
  },
  {
    name: 'Synergy',
    imageUrl: synergyImage,
    linkUrl: 'https://synergyxr.com/'
  },
  {
    name: 'Emulate3D from Rockwell Automation',
    imageUrl: emulate3dImage,
    linkUrl: 'https://www.demo3d.com'
  },
  {
    name: 'Glue',
    imageUrl: glueImage,
    linkUrl: 'https://glue.work'
  },
  {
    name: 'Saxo Bank',
    imageUrl: saxoImage,
    linkUrl: 'https://www.home.saxo'
  },
];


export default class CustomersSection extends Component {


  render() {
    return (
      <div className="customers-section">
        <Carousel quantityVisible={3}>
          {customers.map(this._renderCustomer)}
        </Carousel>
      </div>
    );
  }

  private _renderCustomer = (customer: Customer) => (
    <a key={customer.name} href={customer.linkUrl} target="_blank">
      <img src={customer.imageUrl} alt={customer.name} style={customer.imageStyle}/>
    </a>
  )
}
