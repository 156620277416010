import FileReferenceDefinition from '../FileReferenceDefinition';
import Products from '../../web-common/models/products/Products';
import getSharedAndroidMembers from './android-shared';

const definition: FileReferenceDefinition = {
  name: 'AndroidGeckoWebView',
  type: 'class',
  description: `AndroidGeckoWebView is the [IWebView](/webview/IWebView) implementation used by ${Products.androidGecko.markdownLink}. It also includes additional APIs for Gecko-specific functionality.`,
  inheritsFrom: 'IWebView',
  additionalInterfaces: [
    'IWithAuth',
    'IWithDownloads',
    'IWithFileSelection',
    'IWithFind',
    'IWithKeyDownAndUp',
    'IWithMovablePointer',
    'IWithMutableAudio',
    'IWithNativeJavaScriptDialogs',
    'IWithPointerDownAndUp',
    'IWithPopups',
    'IWithSettableUserAgent',
    'IWithTouch'
  ],
  sidebarSection: 'platform',
  members: [
    ...getSharedAndroidMembers(true),
    {
      name: 'FileSelectionRequested',
      returns: 'EventHandler<FileSelectionEventArgs>',
      type: 'event',
      description: "Indicates that the page requested a file selection dialog. This can happen, for example, when a file input is activated. Call the event args' [Continue(filePaths)](/webview/FileSelectionEventArgs#Continue) callback to provide a file selection or call [Cancel()](/webview/FileSelectionEventArgs#Cancel) to cancel file selection."
    },
    {
      name: 'EnsureBuiltInExtension',
      static: true,
      returns: 'void',
      type: 'method',
      description: "Installs an extension using GeckoView's [WebExtensionController.ensureBuiltIn()](https://mozilla.github.io/geckoview/javadoc/mozilla-central/org/mozilla/geckoview/WebExtensionController.html#ensureBuiltIn-java.lang.String-java.lang.String-) method. The extension is not re-installed if it's already present and has the same version.",
      arguments: [
        {
          type: 'string',
          name: 'uri'
        },
        {
          type: 'string',
          name: 'id'
        }
      ],
      example: `#if UNITY_ANDROID && !UNITY_EDITOR
    AndroidGeckoWebView.EnsureBuiltInExtension(
        "resource://android/assets/your-extension/",
        "example@example.com"
    );
#endif`
    },
    {
      name: 'GetGeckoRuntime',
      returns: 'AndroidJavaObject',
      type: 'method',
      static: true,
      description: "Gets the native [GeckoRuntime](https://mozilla.github.io/geckoview/javadoc/mozilla-central/org/mozilla/geckoview/GeckoRuntime.html) used by 3D WebView. If the GeckoRuntime hasn't been created yet, calling this method causes it to be created.",
      warnings: ["Warning: Adding code that interacts with the native GeckoRuntime directly may interfere with 3D WebView's functionality and vice versa. So, it's highly recommended to stick to 3D WebView's C# APIs whenever possible and only use GetGeckoRuntime() if an equivalent C# API doesn't exist."],
      example: `#if UNITY_ANDROID && !UNITY_EDITOR
    var geckoRuntime = await AndroidGeckoWebView.GetGeckoRuntime();
    var settings = geckoRuntime.Call<AndroidJavaObject>("getSettings");
    var fontSizeScaleFactor = settings.Call<float>("getFontSizeFactor");
    Debug.Log("font size scale factor: " + fontSizeScaleFactor);
#endif`
    },
    {
      name: 'GetNativeWebView',
      returns: 'AndroidJavaObject',
      type: 'method',
      description: "Returns the instance's native [GeckoSession](https://mozilla.github.io/geckoview/javadoc/mozilla-central/org/mozilla/geckoview/GeckoSession.html).",
      warnings: ["Warning: Adding code that interacts with the native GeckoSession directly may interfere with 3D WebView's functionality and vice versa. So, it's highly recommended to stick to 3D WebView's C# APIs whenever possible and only use GetNativeWebView() if an equivalent C# API doesn't exist."],
      example: `await webViewPrefab.WaitUntilInitialized();
#if UNITY_ANDROID && !UNITY_EDITOR
    var androidGeckoWebView = webViewPrefab.WebView as AndroidGeckoWebView;
    var geckoSession = androidGeckoWebView.GetNativeWebView();
    // Call the GeckoSession.purgeHistory() to purge the back / forward history.
    // https://mozilla.github.io/geckoview/javadoc/mozilla-central/org/mozilla/geckoview/GeckoSession.html#purgeHistory()
    // Most native GeckoSession methods must be called on the Android UI thread.
    AndroidGeckoWebView.RunOnAndroidUIThread(() => {
        geckoSession.Call("purgeHistory");
    });
#endif`
    },
    {
      name: 'GetSessionState',
      returns: 'string',
      type: 'method',
      description: "Gets the serialized session state, which can be used to restore the session at a later time using [RestoreSessionState()](#RestoreSessionState).",
      example: `#if UNITY_ANDROID && !UNITY_EDITOR
    var androidGeckoWebView = webViewPrefab.WebView as AndroidGeckoWebView;
    var serializedSessionState = androidGeckoWebView.GetSessionState();
#endif`
    },
    {
      name: 'PauseAll',
      static: true,
      returns: 'void',
      type: 'method',
      description: `Pauses media and rendering for all webview instances until [ResumeAll()](#ResumeAll) is called. 3D WebView automatically calls this method when the application is paused. `,
      example: `#if UNITY_ANDROID && !UNITY_EDITOR
    AndroidGeckoWebView.PauseAll();
#endif`
    },
    {
      name: 'RestoreAudioFocus',
      static: true,
      returns: 'void',
      type: 'method',
      description: `Manually triggers the audio workaround described [here](https://support.vuplex.com/articles/oculus-quest-app-audio-stops). In some cases, 3D WebView may fail to automatically detect the audio issue, and in that scenario, the application can call this method to manually trigger the workaround.`,
      example: `#if UNITY_ANDROID && !UNITY_EDITOR
    AndroidGeckoWebView.RestoreAudioFocus();
#endif`
    },
    {
      name: 'RestoreSessionState',
      returns: 'void',
      type: 'method',
      description: "Restores a previous session using the serialized state returned by calling [GetSessionState()](#GetSessionState) on this webview or a previous webview.",
      example: `await webViewPrefab.WaitUntilInitialized();
#if UNITY_ANDROID && !UNITY_EDITOR
    var androidGeckoWebView = webViewPrefab.WebView as AndroidGeckoWebView;
    androidGeckoWebView.RestoreSessionState(serializedSessionState);
#endif`
    },
    {
      name: 'SetConsoleOutputEnabled',
      static: true,
      returns: 'void',
      type: 'method',
      description: "Sets whether or not web console messages are output to the Android Logcat logs. The default is `false`. Unlike [IWebView.ConsoleMessageLogged](/webview/IWebView#ConsoleMessageLogged), this method includes console messages from iframes and console message like uncaught errors that aren't explicitly passed to a log method like [console.log()](https://developer.mozilla.org/en-US/docs/Web/API/console/log).",
      example: `void Awake() {
    #if UNITY_ANDROID && !UNITY_EDITOR
        AndroidGeckoWebView.SetConsoleOutputEnabled(true);
    #endif
}`,
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ],
      warnings: ['If enabled, Gecko performance may be negatively impacted if content makes heavy use of the console API.']
    },
    {
      name: 'SetDebugLoggingEnabled',
      static: true,
      returns: 'void',
      type: 'method',
      description: `By default, the Gecko browser engine outputs debug messages to the Logcat logs, but you can use this method to disable that. Note that this method can only be called prior to initializing any webviews, so it's recommended to call it from Awake().`,
      example: `void Awake() {
    #if UNITY_ANDROID && !UNITY_EDITOR
        AndroidGeckoWebView.SetDebugLoggingEnabled(false);
    #endif
}`,
      arguments: [
        {
          type: 'bool',
          name: 'enabled'
        }
      ]
    },
    {
      name: 'SetLocales',
      static: true,
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'string[]',
          name: 'locales'
        }
      ],
      description: `Gecko automatically sets the browser engine's locale (which is used for things like the Accept-Language request header) based on the device's system language. However, you can override the locale using this method.`,
      example: `void Awake() {
    #if UNITY_ANDROID && !UNITY_EDITOR
        AndroidGeckoWebView.SetLocales(new string[] {"es-MX", "es-ES"});
    #endif
}`
    },
    {
      name: 'SetPixelDensity',
      static: true,
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'float',
          name: 'pixelDensity'
        }
      ],
      description: "By default, Gecko uses a pixel density of 1.0, but the application can call this method at startup to globally set a different pixel density in order to reduce pixelation and make web content appear more crisp. Note that this method can only be called prior to initializing any webviews, so it's recommended to call it from Awake().",
      example: `void Awake() {
    #if UNITY_ANDROID && !UNITY_EDITOR
        AndroidGeckoWebView.SetPixelDensity(2.0f);
    #endif
}`
    },
    {
      name: 'SetPreferences',
      static: true,
      returns: 'void',
      type: 'method',
      arguments: [
        {
          type: 'Dictionary<string, string>',
          name: 'preferences'
        }
      ],
      description: `Sets Gecko preferences, which can be used to optionally modify the browser engine's settings. Note that this method can only be called prior to initializing any webviews, so it's recommended to call it from Awake()..

The engine's current settings can be viewed by loading the url "about:config" in a webview.
The available Gecko preferences aren't well-documented, but the following pages list some of them:
- [libpref's StaticPrefList.yaml](https://dxr.mozilla.org/mozilla-central/source/modules/libpref/init/StaticPrefList.yaml)
- [libpref's all.js](https://dxr.mozilla.org/mozilla-central/source/modules/libpref/init/all.js)`,
      example: `void Awake() {
    #if UNITY_ANDROID && !UNITY_EDITOR
        AndroidGeckoWebView.SetPreferences(new Dictionary<string, string> {
            ["security.fileuri.strict_origin_policy"] = "false",
            ["formhelper.autozoom"] = "false"
        });
    #endif
}`
    },
    {
      name: 'SetXRContext',
      static: true,
      returns: 'void',
      type: 'method',
      description: "Passes an external XR context to [GeckoVRManager.setExernalContext()](https://mozilla.github.io/geckoview/javadoc/mozilla-central/org/mozilla/geckoview/GeckoVRManager.html#setExternalContext(long)). WebXR isn't yet implemented in 3D WebView, but this method is provided in case others want to attempt to implement it. For more details on implementing WebXR, please see [this article](https://support.vuplex.com/articles/webxr). Note that this method can only be called prior to initializing any webviews, so it's recommended to call it from Awake().",
      example: `void Awake() {
    #if UNITY_ANDROID && !UNITY_EDITOR
        AndroidGeckoWebView.SetXRContext(yourExternalXRContext);
    #endif
}`,
      arguments: [
        {
          type: 'IntPtr',
          name: 'externalXRContext'
        }
      ],
    },
  ]
};
export default definition;
