import React, { Component } from 'react';
import Urls from '../../config/Urls';
import vuplexLogoImage from '../../assets/vuplex-logo.png';
import SearchInput from './SearchInput';
import AccountButton from './AccountButton';
import './styles.scss';

export interface MobileMenuButtonOptions {
  onClick: () => void;
  menuOpen: boolean;
}

interface NavbarProps {
  centerLinks: JSX.Element[];
  centerOnly?: boolean;
  className?: string;
  mobileMenuButton?: MobileMenuButtonOptions,
  search?: boolean;
  siteName?: string;
}

export default class Navbar extends Component<NavbarProps> {

  render() {

    const classNames = ['navbar'];
    if (this.props.search) {
      classNames.push('search-enabled')
    }
    if (this.props.className) {
      classNames.push(this.props.className);
    }
    return (
      <nav className={classNames.join(' ')}>
        <div style={this.props.centerOnly ? { justifyContent: 'center' } : undefined}>
          {this._renderSide()}
          <div className="nav-center">
            {this.props.centerLinks}
            <div className="social-links">
              <a className="social-link" href={Urls.Linkedin}>
                <svg height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-141.363281 0-256 114.636719-256 256s114.636719 256 256 256 256-114.636719 256-256-114.636719-256-256-256zm-74.390625 387h-62.347656v-187.574219h62.347656zm-31.171875-213.1875h-.40625c-20.921875 0-34.453125-14.402344-34.453125-32.402344 0-18.40625 13.945313-32.410156 35.273437-32.410156 21.328126 0 34.453126 14.003906 34.859376 32.410156 0 18-13.53125 32.402344-35.273438 32.402344zm255.984375 213.1875h-62.339844v-100.347656c0-25.21875-9.027343-42.417969-31.585937-42.417969-17.222656 0-27.480469 11.601563-31.988282 22.800781-1.648437 4.007813-2.050781 9.609375-2.050781 15.214844v104.75h-62.34375s.816407-169.976562 0-187.574219h62.34375v26.558594c8.285157-12.78125 23.109375-30.960937 56.1875-30.960937 41.019531 0 71.777344 26.808593 71.777344 84.421874zm0 0" fill="white" /></svg>
              </a>
              <a className="social-link" href={Urls.Facebook}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 167.657 167.657" width="512" height="512"><path d="M83.829.349C37.532.349 0 37.881 0 84.178c0 41.523 30.222 75.911 69.848 82.57v-65.081H49.626v-23.42h20.222V60.978c0-20.037 12.238-30.956 30.115-30.956 8.562 0 15.92.638 18.056.919v20.944l-12.399.006c-9.72 0-11.594 4.618-11.594 11.397v14.947h23.193l-3.025 23.42H94.026v65.653c41.476-5.048 73.631-40.312 73.631-83.154 0-46.273-37.532-83.805-83.828-83.805z" fill="white" /></svg>
              </a>
              <a className="social-link" href={Urls.Bluesky}>
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="256" height="256" viewBox="0 0 256 256">
                  <defs>
                  </defs>
                  <g style={{stroke: 'none', strokeWidth: '0', strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fill: 'none', fillRule: 'nonzero', opacity: 1}} transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                    <path fill="white" d="M 45 90 L 45 90 C 20.147 90 0 69.853 0 45 v 0 C 0 20.147 20.147 0 45 0 h 0 c 24.853 0 45 20.147 45 45 v 0 C 90 69.853 69.853 90 45 90 z" style={{stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fillRule: 'nonzero', opacity: 1}} transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                    <path fill="#12bae9" d="M 29.977 25.793 C 36.058 30.358 42.599 39.615 45 44.582 c 2.402 -4.967 8.942 -14.224 15.023 -18.789 c 4.388 -3.294 11.497 -5.843 11.497 2.267 c 0 1.62 -0.929 13.607 -1.473 15.553 c -1.893 6.766 -8.792 8.491 -14.929 7.447 c 10.727 1.826 13.456 7.873 7.562 13.92 c -11.192 11.485 -16.087 -2.882 -17.341 -6.563 c -0.23 -0.675 -0.337 -0.991 -0.339 -0.722 c -0.002 -0.268 -0.109 0.047 -0.339 0.722 c -1.254 3.681 -6.148 18.048 -17.341 6.563 c -5.893 -6.047 -3.165 -12.095 7.562 -13.92 c -6.137 1.044 -13.036 -0.681 -14.929 -7.447 c -0.545 -1.946 -1.473 -13.933 -1.473 -15.553 C 18.48 19.95 25.589 22.499 29.977 25.793 L 29.977 25.793 z" style={{stroke: 'none', strokeWidth: 1, strokeDasharray: 'none', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, fillRule: 'nonzero', opacity: 1}} transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  </g>
                </svg>
              </a>
              {/* <a className="social-link" href={Urls.GitHub}>
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" fill="white" /></svg>
              </a> */}
              {/* <a className="social-link" href={Urls.Twitter}>
                <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 49.652 49.652"><path d="M24.826 0C11.137 0 0 11.137 0 24.826c0 13.688 11.137 24.826 24.826 24.826 13.688 0 24.826-11.138 24.826-24.826C49.652 11.137 38.516 0 24.826 0zm11.075 19.144c.011.246.017.494.017.742 0 7.551-5.746 16.255-16.259 16.255-3.227 0-6.231-.943-8.759-2.565.447.053.902.08 1.363.08 2.678 0 5.141-.914 7.097-2.446a5.72 5.72 0 0 1-5.338-3.969 5.76 5.76 0 0 0 2.58-.096 5.715 5.715 0 0 1-4.583-5.603l.001-.072a5.69 5.69 0 0 0 2.587.714 5.71 5.71 0 0 1-2.541-4.755c0-1.048.281-2.03.773-2.874a16.225 16.225 0 0 0 11.777 5.972 5.707 5.707 0 0 1-.147-1.303 5.714 5.714 0 0 1 9.884-3.91 11.406 11.406 0 0 0 3.63-1.387 5.74 5.74 0 0 1-2.516 3.162 11.36 11.36 0 0 0 3.282-.899 11.494 11.494 0 0 1-2.848 2.954z" fill="white" /></svg>
              </a> */}
            </div>
          </div>
          {this._renderRightSide()}
        </div>
      </nav>
    );
  }

  private _renderMenuIcon() {

    if (this.props.mobileMenuButton!.menuOpen) {
      return <div className="close">×</div>;
    }
    return (
      <div className="hamburger" >
        <div /><div /><div />
      </div>
    );
  }

  private _renderMobileMenuButton() {

    if (!this.props.mobileMenuButton) {
      return;
    }
    return (
      <div className="menu-button" role="button" tabIndex={0} onClick={this.props.mobileMenuButton.onClick}>
        {this._renderMenuIcon()}
      </div>
    );
  }

  private _renderRightSide() {

    return (
      <div className="nav-right">
        {this.props.search && <SearchInput/>}
        {<AccountButton/>}
      </div>
    );
  }

  private _renderSide(className?: string) {

    if (this.props.centerOnly) {
      return;
    }
    return (
      <div className={`nav-side ${className || ''}`}>
        {this._renderMobileMenuButton()}
        <a className="nav-logo" href="https://vuplex.com">
          <img src={vuplexLogoImage} alt="Vuplex logo" />
        </a>
        <div className="nav-slash" />
        <a className="nav-header" href="/">{this.props.siteName || 'vuplex'}</a>
      </div>
    )
  }
}
