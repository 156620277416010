import Products from '../../web-common/models/products/Products';
import FileReferenceDefinition from '../FileReferenceDefinition';

const definition: FileReferenceDefinition = {
  name: 'WebGLWebView',
  type: 'class',
  description: `WebGLWebView is the [IWebView](/webview/IWebView) implementation used by ${Products.webGL.markdownLink}. It also includes additional APIs for WebGL-specific functionality.`,
  inheritsFrom: 'IWebView',
  additionalInterfaces: [
    'IWithNative2DMode'
  ],
  sidebarSection: 'platform',
  members: [
    {
      name: 'IFrameElementID',
      type: 'property',
      returns: 'string',
      description: "Gets the unique `id` attribute of the webview's [&lt;iframe&gt;](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe) element.",
      example: `await canvasWebViewPrefab.WaitUntilInitialized();
#if UNITY_WEBGL && !UNITY_EDITOR
    var webGLWebView = canvasWebViewPrefab.WebView as WebGLWebView;
    Debug.Log("IFrame ID: " + webGLWebView.IFrameElementID);
#endif`
    },
    {
      name: 'CanAccessIFrameContent',
      type: 'method',
      returns: 'bool',
      description: "Indicates whether 2D WebView can access the content in the webview's iframe. If the iframe's content can't be accessed, then most of the IWebView APIs become disabled. For more information, please see [this article](https://support.vuplex.com/articles/webgl-limitations#cross-origin-limitation).",
      example: `await canvasWebViewPrefab.WaitUntilInitialized();
#if UNITY_WEBGL && !UNITY_EDITOR
    var webGLWebView = canvasWebViewPrefab.WebView as WebGLWebView;
    if (webGLWebView.CanAccessIFrameContent()) {
        Debug.Log("The iframe content can be accessed 👍");
    }
#endif`
    },
    {
      name: 'ExecuteJavaScriptLocally',
      type: 'method',
      static: true,
      returns: 'string',
      arguments: [
        {
          name: 'javaScript',
          type: 'string'
        }
      ],
      description: "Executes the given JavaScript locally in the Unity app's window and returns the result.",
      example: `#if UNITY_WEBGL && !UNITY_EDITOR
    // Gets the Unity app's web page title.
    var title = WebGLWebView.ExecuteJavaScriptLocally("document.title");
    Debug.Log("Title: " + title);
#endif`
    },
    {
      name: 'FocusUnity',
      type: 'method',
      static: true,
      returns: 'void',
      description: `Focuses the Unity game view, which enables the Unity application to detect keyboard input.

When the user clicks on a webview, their web browser shifts input focus from the Unity game view to the webview. While the webview is focused, the Unity application is unable to detect keyboard input (i.e. keyboard APIs like Input.GetKeyDown() don't work). By default, the Unity game view isn't refocused until the user clicks on it, at which point the Unity application is once again able to detect keyboard input. However, the application can optionally use this method to programmatically refocus the Unity game view at any time without requiring the user to actually click on it.`,
      example: `#if UNITY_WEBGL && !UNITY_EDITOR
    WebGLWebView.FocusUnity();
#endif`,
      seeAlso: '[SetFocusUnityOnHover()](#SetFocusUnityOnHover)',
      warnings: [
        'It is not recommended to call this method on each frame in an Update() loop because that may prevent the webview from being able to handle mouse and keyboard input correctly. If you wish for the Unity game view to be focused automatically, please use [SetFocusUnityOnHover()](#SetFocusUnityOnHover) instead.'
      ]
    },
    {
      name: 'SetDevicePixelRatio',
      type: 'method',
      static: true,
      returns: 'void',
      arguments: [
        {
          name: 'devicePixelRatio',
          type: 'float'
        }
      ],
      description: `Overrides the value of \`devicePixelRatio\` that 2D WebView uses to determine the correct size and coordinates of webviews.

Starting in Unity 2019.3, Unity scales the UI by default based on the browser's [window.devicePixelRatio](https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio) value. However, it's possible for an application to override the \`devicePixelRatio\` value by passing a value for \`config.devicePixelRatio\` to Unity's [createUnityInstance()](https://docs.unity3d.com/2020.3/Documentation/Manual/webgl-templates.html) JavaScript function. In some versions of Unity, the default index.html template sets \`config.devicePixelRatio = 1\` on mobile. In order for 2D WebView to size and position webviews correctly, it must determine the value of \`devicePixelRatio\` to use. Since there's no API to get a reference to the Unity instance that the application created with \`createUnityInstance()\`, 2D WebView tries to detect if \`config.devicePixelRatio\` was passed to \`createUnityInstance()\` by checking for the presence of a global \`config\` JavaScript variable. If there is a global variable named \`config\` with a \`devicePixelRatio\` property, then 2D WebView uses that value, otherwise it defaults to using \`window.devicePixelRatio\`. This approach works for Unity's default index.html templates, but it may not work if the application uses a custom HTML template or changes the name of the \`config\` variable in the default template. In those cases, the application can use this method to pass the overridden value of \`devicePixelRatio\` to 2D WebView.`,
      example: `void Awake() {
    #if UNITY_WEBGL && !UNITY_EDITOR
        WebGLWebView.SetDevicePixelRatio(1);
    #endif
}`
    },
    {
      name: 'SetFocusUnityOnHover',
      type: 'method',
      static: true,
      returns: 'void',
      arguments: [
        {
          name: 'enabled',
          type: 'bool'
        }
      ],
      description: `Sets whether 2D WebView automatically refocuses the Unity game view when the user's mouse leaves the webview and hovers over the Unity game view. The default is \`false\`.

When the user clicks on a webview, their web browser shifts input focus from the Unity game view to the webview. While the webview is focused, the Unity application is unable to detect keyboard input (i.e. keyboard APIs like Input.GetKeyDown() don't work). By default, the Unity game view isn't refocused until the user clicks on it, at which point the Unity application is once again able to detect keyboard input. However, the application can optionally use this method to make it so that the Unity game view is automatically refocused when the user's mouse leaves the webview and hovers over the Unity game view (i.e. without requiring them to actually click on the Unity game view).`,
      example: `void Awake() {
    #if UNITY_WEBGL && !UNITY_EDITOR
        WebGLWebView.SetFocusUnityOnHover(true);
    #endif
}`,
      seeAlso: ['[FocusUnity()](#FocusUnity)']
    },
    {
      name: 'SetFullscreenEnabled',
      type: 'method',
      static: true,
      returns: 'void',
      arguments: [
        {
          name: 'enabled',
          type: 'bool'
        }
      ],
      description: "Sets whether web pages can use the [JavaScript Fullscreen API](https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API) to make an HTML element occupy the device's entire screen. The default is `true`, meaning that the JavaScript Fullscreen API is enabled by default.",
      example: `void Awake() {
    #if UNITY_WEBGL && !UNITY_EDITOR
        WebGLWebView.SetFullscreenEnabled(false);
    #endif
}`
    },
    {
      name: 'SetGeolocationEnabled',
      type: 'method',
      static: true,
      returns: 'void',
      arguments: [
        {
          name: 'enabled',
          type: 'bool'
        }
      ],
      description: "Sets whether the JavaScript [Geolocation API](https://developer.mozilla.org/en-US/docs/Web/API/Geolocation_API) is enabled for webviews. The default is `false`.",
      example: `void Awake() {
    #if UNITY_WEBGL && !UNITY_EDITOR
        WebGLWebView.SetGeolocationEnabled(true);
    #endif
}`
    },
    {
      name: 'SetScreenSharingEnabled',
      type: 'method',
      static: true,
      returns: 'void',
      arguments: [
        {
          name: 'enabled',
          type: 'bool'
        }
      ],
      description: "Sets whether screen sharing is enabled for webviews. The default is `false`.",
      example: `void Awake() {
    #if UNITY_WEBGL && !UNITY_EDITOR
        WebGLWebView.SetScreenSharingEnabled(true);
    #endif
}`
    },
    {
      name: 'SetUnityContainerElementID',
      type: 'method',
      static: true,
      returns: 'void',
      arguments: [
        {
          name: 'containerId',
          type: 'string'
        }
      ],
      description: "Explicitly sets the HTML element that 2D WebView should use as the Unity app container. 2D WebView automatically detects the Unity app container element if its ID is set to one of the default values of \"unityContainer\" or \"unity-container\". However, if your app uses a custom WebGL template that uses a different ID for the container element, you must call this method to set the container element ID. 2D WebView for WebGL works by adding [&lt;iframe&gt;](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/iframe) elements to the app container, so it's unable to function correctly if it's unable to find the Unity app container element.",
      example: `void Awake() {
    #if UNITY_WEBGL && !UNITY_EDITOR
        WebGLWebView.SetUnityContainerElementID("your-custom-id");
    #endif
}`
    },
  ]
};
export default definition;
